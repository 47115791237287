import React, { useState } from 'react';
import { Text, TextIcon } from '@components';
import HsSearchStyles from './styles';
import { SelectHsCodeType } from './types';

export type HsSearchResultsIndividualProps = {
  description: string;
  HSCode: string;
  selectHsCode: SelectHsCodeType;
  selected: boolean;
  hideActions?: boolean;
};

const HsSearchResultsIndividual: React.FC<HsSearchResultsIndividualProps> = props => {
  const [hover, setHover] = useState(false);

  const { description, HSCode, selectHsCode, selected } = props;

  const clickAction = () => {
    if (!selected) {
      return selectHsCode(HSCode, description);
    }
  };

  return (
    <HsSearchStyles.SubheadingBoxIndividual
      className="searchResult"
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      key={HSCode}
      hideActions={props.hideActions}
      onClick={clickAction}
      data-analytics-id={selected ? '' : '23-hs-code-search-add'}
    >
      <HsSearchStyles.HSCodeIconContainer>
        {HSCode && (
          <Text.SmallParagraph weight="bold" align="right">
            {`${HSCode.slice(0, 4)}.${HSCode.slice(-2)}`}
          </Text.SmallParagraph>
        )}
        {!props.hideActions && (
          <>
            {selected ? (
              <HsSearchStyles.IconContainer size="18px">
                {hover ? <img src="/static/icons/checkWhite.svg" /> : <img src="/static/icons/checkGreen.svg" />}
              </HsSearchStyles.IconContainer>
            ) : (
              <HsSearchStyles.ItemAction
                data-analytics-id="23-hs-code-search-add-2"
                data-test-id="add-search-result-button"
              >
                <TextIcon
                  variant="Tiny"
                  size="13px"
                  weight="bold"
                  color="onDarkLight"
                  spacing="none"
                  rightIconComponent={() => <img src="/static/icons/plusTinyWhite.svg" />}
                >
                  Add
                </TextIcon>
              </HsSearchStyles.ItemAction>
            )}
          </>
        )}
      </HsSearchStyles.HSCodeIconContainer>
      <Text.SmallerParagraph color="onWhiteLight">{description}</Text.SmallerParagraph>
    </HsSearchStyles.SubheadingBoxIndividual>
  );
};

export default HsSearchResultsIndividual;
