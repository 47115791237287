import { helpers, Scrollbar } from '@components';
import styled from 'styled-components';
import { TabletAndDown } from '../stylingUtils';

export default {
  ChapterBox: styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    height: 10%;
    background-color: #ffffff;
    padding: 0;

    div {
      background-color: ${props => props.theme.colors.primary};
      margin: 0 ${props => props.theme.spacing.sm} 0 0;
      h3 {
        padding: 0.5em;
        color: white;
        font-size: ${props => props.theme.spacing.md};
        height: 100%;
      }
    }

    p {
      padding: 0.7em 0.5em 0.7em 0;
      line-height: 24px;
    }
  `,

  HeadingsPrefixesBox: styled.div`
    max-width: 100%;
    background-color: #ffffff;
  `,

  HeadingBox: styled.div`
    background-color: ${props => props.theme.colors.lightGray};
    display: flex;
    padding: ${props => props.theme.spacing.sm} 0;

    p:first-child {
      flex: 0 0 90px;
      text-align: right;
      font-size: 20px;
      margin-right: ${props => props.theme.spacing.sm};
    }
  `,

  SubheadingBox: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    p:first-child {
      flex: 0 0 90px;
      padding: 9px ${props => props.theme.spacing.sm} 9px 0;
      text-align: right;
      align-self: flex-start;
      border: none;
      color: ${props => props.theme.colors.text.onWhiteLight};
    }

    p {
      width: 100%;
      text-align: left;
      border-left: 2px solid ${props => props.theme.colors.primary};
      padding: 9px ${props => props.theme.spacing.sm} 9px;
    }

    button {
      background: none;
      border: none;
      outline: none;
    }

    .checkIcon {
      margin: 8px;
    }
  `,

  SubheadingBoxIndividual: styled.div<{ hideActions?: boolean }>`
    background-color: ${props => props.theme.colors.white};
    padding: ${props => props.theme.spacing.sm};
    padding-top: 0;
    align-items: center;
    cursor: pointer;

    p:first-child {
      margin-right: ${props => props.theme.spacing.sm};
      border: none;
      padding: 0;
    }

    p {
      text-align: left;
      margin-right: 48px;
    }

    button {
      background: none;
      border: none;
      outline: none;
    }

    .checkIcon {
      margin: 8px;
    }
    ${props =>
      !props.hideActions
        ? `
      &:hover {
        background-color: ${props.theme.colors.primary};
      p {
        color: ${props.theme.colors.white};
      }
      div:first-child {
        border-top: solid 1px ${props.theme.colors.primary};
      }
    }
    `
        : ''}
  `,

  HSCodeIconContainer: styled.div`
    display: flex;
    justify-content: space-between;
    padding-top: ${props => props.theme.spacing.sm};
    border-top: solid 1px ${props => props.theme.colors.lightBorderGray};
  `,

  NoProductContainer: styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    height: 96px;
    width: 100%;
    border: solid 1px ${props => props.theme.colors.lighterBorderGray};
  `,

  PrefixBox: styled.div`
    display: flex;
    flex-direction: column;
    padding: calc(${props => props.theme.spacing.default} / 2) ${props => props.theme.spacing.xsm}
      calc(${props => props.theme.spacing.default} / 2) 0;
  `,

  PrefixDescription: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    border-left: 2px solid ${props => props.theme.colors.primary};
    margin-left: 106px;
    padding: ${props => props.theme.spacing.sm} 0;
    padding-left: ${props => props.theme.spacing.sm};
  `,

  PrefixResults: styled.div`
    display: flex;
    flex-direction: column;
  `,

  SelectedContainer: styled.div``,

  /* Selected Products Cards styles */
  SelectedProductCard: styled.div`
    display: flex;
    width: auto;
    margin-bottom: ${props => props.theme.spacing.sm};

    div:first-child {
      position: relative;
      z-index: 1;
    }

    .prefixDescription {
      padding: ${props => props.theme.spacing.default} 0 0 ${props => props.theme.spacing.lg};
    }
  `,

  SelectedProductDescription: styled.div`
    display: flex;
    justify-content: space-between;
    min-height: 100px;
    a img {
      cursor: pointer;
      position: absolute;
      bottom: 32px;
      right: 0;
      &.flipArrow {
        transform: rotate(180deg);
      }
    }

    ${props => TabletAndDown(props)} {
      min-height: 160px;
    }
  `,
  SelectedProductDescriptionPrefix: styled.div`
    display: flex;
    justify-content: space-between;
    padding: ${props => props.theme.spacing.sm};
  `,

  SelectedProductDetails: styled.div`
    display: flex;
    flex-direction: column;
    padding-top: ${props => props.theme.spacing.sm};
    background-color: ${props => props.theme.colors.white};
    border-top-width: 1px;
    border-top-color: ${props => props.theme.colors.disabled};
    border-top-style: solid;
    width: 95%;
  `,

  SelectedProductDetailsContainer: styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
  `,

  IconContainer: styled.div<{ size: string; flipped?: boolean }>`
    width: ${props => props.size};
    height: ${props => props.size};
    img {
      ${props => (props.flipped ? 'transform: rotate(180deg);' : '')};
    }
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    user-select: none;
  `,
  ItemAction: styled.button`
    padding: 0;
    margin: 0;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    ${helpers}
  `,
  SelectedActionContainer: styled.div`
    padding: calc(${props => props.theme.spacing.default} / 2);
    margin-left: ${props => props.theme.spacing.sm};
  `,

  Container: styled.div`
    display: flex;
    height: 100%;
    flex-direction: row;
    justify-content: center;
    margin: 0 auto;
    padding-bottom: ${props => props.theme.spacing.md};
  `,

  SearchBar: styled.div`
    width: 100%;
    position: relative;
  `,

  ClearButton: styled.button`
    position: absolute;
    right: 0;
    margin: 0;
    padding: ${props => props.theme.spacing.sm};
    height: 100%;
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    line-height: 0;
    font-size: ${props => props.theme.spacing.md};
    font-weight: lighter;
  `,

  ContentContainer: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
  `,

  ScrollContainer: styled.div`
    width: 100%;
    max-height: 250px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-left: ${props => props.theme.spacing.sm};
    margin-left: -${props => props.theme.spacing.sm};
    ${Scrollbar};

    .searchResult:first-child > :nth-child(1) {
      border-top: none;
    }
  `,

  DisplayHSFixedHeight: styled.div`
    width: 100%;
    height: 250px;
    background-color: ${props => props.theme.colors.background};
  `,

  TradeInsightsData: styled.div<{ expanded?: boolean }>`
    ${props => (props.expanded ? '' : `border-top: 1px solid ${props.theme.colors.disabled};`)}
    width: 95%;
  `
};
