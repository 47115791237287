import { Spacing } from '@components';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import ProductTeaser from '../../../components/HsSearch/ProductTeaser';
import { doDeselectProduct } from '../../../redux/modules/product/actions';
import { AppState } from '../../../redux/reducers';

export type SelectedProductsProps = SelectedProductsReducerProps;

const SelectedProducts: React.FC<SelectedProductsProps> = ({ selected, doDeselectProduct }) => (
  <>
    {selected.map(({ // eslint-disable-next-line @typescript-eslint/no-unused-vars
      tradeInsightsData, ...product }) => (
      <Spacing paddingRight="xsm" key={product.HSCode}>
        <ProductTeaser product={product} removeSelectedHsCode={doDeselectProduct} />
      </Spacing>
    ))}
  </>
);

const mapStateToProps = ({ productReducer }: AppState) => ({ selected: productReducer.selected });
const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ doDeselectProduct }, dispatch);

export type SelectedProductsReducerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(SelectedProducts);
