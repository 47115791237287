import React, { useState } from 'react';
import HsSearchStyles from './styles';
import { SelectedProduct } from '../../redux/modules/product/interfaces';
import { MarketProductPotential } from '../../redux/modules/marketGuide/interfaces';
import { numberNotation } from '../../utils/numberUtils';
import { getIdFromNotation, useLocale } from '../../locales';
import { Flex, Loader, Tile, Text, LocalizedText, Spacing } from '@components';

type ProductTeaserProps = {
  removeSelectedHsCode?: (HSCode: string) => void;
  largerPadding?: boolean;
  hidePotential?: boolean;
  loading?: boolean;
  product?: SelectedProduct;
  showMarketPotential?: boolean;
  marketProductPotential?: MarketProductPotential[];
  marketProductPotentialLoading?: boolean;
};

const ProductTeaser: React.FC<ProductTeaserProps> = props => {
  const { product, removeSelectedHsCode, loading } = props;
  const [showLongDescription, setShowLongDescription] = useState(false);

  const ProductTeaserLoading = () => (
    <HsSearchStyles.SelectedProductCard>
      <Tile
        paddingHorizontal={props.largerPadding ? 'md' : 'none'}
        paddingVertical={props.largerPadding ? 'default' : 'none'}
      >
        <Flex>
          <Text.H6 weight="bold" noMargin marginRight="sm" align="right">
            <Loader width="40px" height="20%" padding="xsm" />
          </Text.H6>
          <Text.SmallerParagraph color="onWhiteLight" fullWidth marginBottom="xmd" as="div">
            <Loader width="150px" padding="sm" />
          </Text.SmallerParagraph>
        </Flex>
      </Tile>
    </HsSearchStyles.SelectedProductCard>
  );

  if (loading || !product) {
    return <ProductTeaserLoading />;
  }

  const { HSCode, description, tradeInsightsData } = product;

  const TradeInsightDataDisplay = () => {
    if (!props.hidePotential && tradeInsightsData && tradeInsightsData.potential) {
      const locale = useLocale();
      const [potentialVal, potentialNotation, potentialFull] = numberNotation(tradeInsightsData.potential, locale);

      return (
        <Flex alignItems="center" justifyContent="center">
          <HsSearchStyles.TradeInsightsData>
            <Flex alignItems="baseline" justifyContent="center" paddingTop={props.largerPadding ? 'sm' : 'xsm'}>
              <Text.H5Responsive weight="bold" color="onWhitePrimary" marginRight="xsm">
                CHF
              </Text.H5Responsive>
              <Text.H3Responsive size="54px" weight="bold" color="onWhitePrimary">
                {potentialNotation === 'K' ? `${potentialFull} ` : `${potentialVal} `}
                {potentialNotation !== 'D' && potentialNotation !== 'K' && (
                  <LocalizedText
                    id={getIdFromNotation(potentialNotation)}
                    values={{ number: potentialVal }}
                    description="Number notation"
                  />
                )}
              </Text.H3Responsive>
            </Flex>
          </HsSearchStyles.TradeInsightsData>
        </Flex>
      );
    } else {
      return null;
    }
  };

  const MarketProductPotential = () => {
    let marketProduct: MarketProductPotential[] = [];

    if (props.marketProductPotentialLoading) {
      return <Loader padding="xsm" />;
    }

    if (props.marketProductPotential && props.marketProductPotential.length > 0 && props.showMarketPotential) {
      marketProduct = props.marketProductPotential.filter(item => item.HSCode === HSCode);

      if (marketProduct.length > 0) {
        const locale = useLocale();

        const [marketPotentialVal, marketPotentialNotation, marketPotentialFull] = numberNotation(
          marketProduct[0].marketProductPotential,
          locale
        );

        return (
          <Flex alignItems="center" justifyContent="center">
            <HsSearchStyles.TradeInsightsData>
              <Flex alignItems="baseline" justifyContent="center" paddingTop={props.largerPadding ? 'sm' : 'xsm'}>
                <Text.H5Responsive weight="bold" color="onWhitePrimary" marginRight="xsm">
                  CHF
                </Text.H5Responsive>
                <Text.H3Responsive size="54px" weight="bold" color="onWhitePrimary">
                  {marketPotentialNotation === 'K' ? `${marketPotentialFull} ` : `${marketPotentialVal} `}
                  {marketPotentialNotation !== 'D' && marketPotentialNotation !== 'K' && (
                    <LocalizedText
                      id={getIdFromNotation(marketPotentialNotation)}
                      values={{ number: marketPotentialVal }}
                      description="Number notation"
                    />
                  )}
                </Text.H3Responsive>
              </Flex>
            </HsSearchStyles.TradeInsightsData>
          </Flex>
        );
      }

      return (
        <Flex alignItems="center" justifyContent="center">
          <HsSearchStyles.TradeInsightsData>
            <Flex alignItems="baseline" justifyContent="center" paddingTop={props.largerPadding ? 'sm' : 'xsm'}>
              <Text.H3Responsive size="54px" weight="bold" color="onWhitePrimary">
                <LocalizedText id="markets.noData" description="No data for product" />
              </Text.H3Responsive>
            </Flex>
          </HsSearchStyles.TradeInsightsData>
        </Flex>
      );
    }

    return null;
  };

  const TrimmedDescription = ({ description, maxLength }: { description: string; maxLength: number }) => {
    const showToggle = description.length > maxLength;
    if (description.length <= maxLength) {
      return (
        <Text.SmallerParagraph color="onWhiteLight" fullWidth>
          {description}
        </Text.SmallerParagraph>
      );
    }

    return (
      <>
        <Text.SmallerParagraph color="onWhiteLight" fullWidth>
          {showToggle && !showLongDescription ? `${description.substr(0, maxLength).trim()}...` : description}
        </Text.SmallerParagraph>
        {showToggle && (
          <a onClick={() => setShowLongDescription(!showLongDescription)}>
            <img className={showLongDescription ? 'flipArrow' : ''} src="/static/icons/semiArrowDownTinyPrimary.svg" />
          </a>
        )}
      </>
    );
  };

  return (
    <HsSearchStyles.SelectedProductCard>
      <Tile noPadding fullWidth>
        <Spacing
          marginHorizontal={props.largerPadding ? 'md' : 'none'}
          marginVertical={props.largerPadding ? 'default' : 'none'}
        >
          <HsSearchStyles.SelectedProductDescription>
            <Flex paddingTop="default" paddingLeft="sm" paddingBottom="default" mobile>
              {HSCode && (
                <Text.H6 weight="bold" noMargin marginRight="sm" size="18px">
                  {`${HSCode.slice(0, 4)}.${HSCode.slice(-2)}`}
                </Text.H6>
              )}
              <TrimmedDescription description={description} maxLength={100} />
            </Flex>
            <HsSearchStyles.SelectedActionContainer>
              {removeSelectedHsCode && (
                <HsSearchStyles.ItemAction
                  onClick={() => removeSelectedHsCode(HSCode)}
                  data-analytics-id="hs-code-search-remove"
                >
                  <img src="/static/icons/trashPrimary.svg" />
                </HsSearchStyles.ItemAction>
              )}
            </HsSearchStyles.SelectedActionContainer>
          </HsSearchStyles.SelectedProductDescription>
          <TradeInsightDataDisplay />
          <MarketProductPotential />
        </Spacing>
      </Tile>
    </HsSearchStyles.SelectedProductCard>
  );
};

export default ProductTeaser;
