import React, { KeyboardEvent, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import ShowResults from '../../elements/Products/ProductResults';
import ShowSelected from '../../elements/Products/SelectedProducts';
import { Product, SelectedProduct } from '../../redux/modules/product/interfaces';
import TooltipHelpText from '../TooltipHelpText';
import HsSearchStyles from './styles';
import LocalizedText from 'src/components/LocalizedText';
import { Flex, Input, Text } from '@components';

// Types
export type HsSearchComponentPropsType = {
  searchTerms: string;
  doSearchProducts: () => void;
  doChangeSearch: (search: string) => void;
  selected: SelectedProduct[];
  searchResults: Product[];
  loading: boolean;
  notFound: boolean;
  showBigTitle?: boolean;
  hideResults?: boolean;
};

const HsSearchComponent: React.FC<HsSearchComponentPropsType> = props => {
  const intl = useIntl();
  const [showResults, setShowResults] = useState(true);
  const [searchTermsError, setSearchTermsError] = useState(false);

  useEffect(() => {
    setShowResults(true);
  }, [props.searchResults]);

  function debounce(func: () => void, wait: number) {
    let timeout: number | null;
    return function (this: void, ...args: Array<number | string>) {
      /* eslint-disable @typescript-eslint/no-this-alias */
      const context = this;
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(() => {
        timeout = null;
        func.apply(context, args as []);
      }, wait);
    };
  }

  const debounceHsSearch = useCallback(debounce(props.doSearchProducts, 300), []);

  // Limiting searches to at least 3 characters, display an error if the user hits enter with less than 3 characters
  const handleKeyUp = (e: KeyboardEvent<HTMLInputElement>) => {
    if (props.searchTerms.length <= 2 && e.key !== 'Enter') {
      setSearchTermsError(false);
    } else if (props.searchTerms.length <= 2 && e.key === 'Enter') {
      setSearchTermsError(true);
    } else if (props.searchTerms.length >= 3) {
      setSearchTermsError(false);
      debounceHsSearch();
    }
  };

  return (
    <HsSearchStyles.Container>
      <HsSearchStyles.ContentContainer>
        <Input.Group marginBottom="none">
          <TooltipHelpText
            variant={props.showBigTitle ? 'H4Responsive' : 'H6'}
            weight="normal"
            noPadding
            marginBottom="sm"
            iconSize="20px"
            contentTranslationKey="signup.productSearchTooltip"
          >
            {props.showBigTitle ? (
              <LocalizedText id="dashboard.productEditTitle" description="Edit components title" />
            ) : (
              <LocalizedText id="signup.productSearchTitle" description="Search components title" />
            )}
          </TooltipHelpText>
          <Text.SmallerParagraph color="onWhiteSecondary" marginBottom="sm">
            <LocalizedText
              supportHtml={true}
              content={intl.formatMessage({
                id: props.showBigTitle ? 'dashboard.deelsDisclaimer' : 'signup.deelsDisclaimer'
              })}
              description="DEELS Disclaimer"
            />
          </Text.SmallerParagraph>
          <Flex>
            <HsSearchStyles.SearchBar>
              <Input.Text
                key={'hsSearch'}
                autoFocus
                height="56px"
                value={props.searchTerms}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.doChangeSearch(e.target.value)}
                onKeyUp={(e: KeyboardEvent<HTMLInputElement>) => handleKeyUp(e)}
                placeholder={intl.formatMessage({ id: 'signup.hsSearchPlaceholder' })}
                data-analytics-id="hs-code-search-product-name"
                data-hj-allow
              />
              {props.searchTerms && props.searchTerms.length > 0 && (
                <HsSearchStyles.ClearButton onClick={() => props.doChangeSearch('')}>
                  &times;
                </HsSearchStyles.ClearButton>
              )}
            </HsSearchStyles.SearchBar>
          </Flex>
        </Input.Group>
        {searchTermsError && (
          <Text.H6 color="onWhiteError" align="center" marginTop="md" marginBottom="md">
            <LocalizedText id="signup.hsSearchLengthError" description="Searches must be at least 3 characters" />
          </Text.H6>
        )}
        {props.searchTerms.length >= 3 && showResults && (
          <HsSearchStyles.DisplayHSFixedHeight>
            {showResults && props.searchResults && props.searchResults.length > 0 && (
              <>
                <HsSearchStyles.ScrollContainer>
                  <ShowResults hideActions={props.hideResults} />
                </HsSearchStyles.ScrollContainer>
              </>
            )}
            {props.notFound && (
              <Text.H6 color="onWhiteLight" align="center" marginTop="md" marginBottom="md">
                <LocalizedText id="signup.productSearchNotFound" description="Nothing found message" />
              </Text.H6>
            )}
          </HsSearchStyles.DisplayHSFixedHeight>
        )}
        {!props.hideResults && (
          <>
            <Flex justifyContent="flex-end" onClick={() => setShowResults(!showResults)}></Flex>
            <Text.H6 weight="bold" marginBottom="sm" marginTop="md">
              <LocalizedText id="signup.selectedProductsTitle" description="Selected products title" />
            </Text.H6>
            {props.selected && props.selected.length <= 0 ? (
              <HsSearchStyles.NoProductContainer>
                <Text.SmallerParagraph color="onWhiteLight">
                  <LocalizedText id="signup.noProductsSelected" description="No products selected" />
                </Text.SmallerParagraph>
              </HsSearchStyles.NoProductContainer>
            ) : (
              <HsSearchStyles.ScrollContainer>
                <ShowSelected />
              </HsSearchStyles.ScrollContainer>
            )}
          </>
        )}
      </HsSearchStyles.ContentContainer>
    </HsSearchStyles.Container>
  );
};

export default HsSearchComponent;
