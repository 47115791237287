import React from 'react';
import { AppState } from '../../redux/reducers';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import {
  doChangeSearch,
  doSearchProducts,
  doSelectProduct,
  doDeselectProduct
} from '../../redux/modules/product/actions';
import HsSearchComponent from '../../components/HsSearch';

export type HsSearchContainerProps = ReducerProps & ComponentProps;

type ComponentProps = {
  showBigTitle?: boolean;
  hideResults?: boolean;
};

const HsSearchContainer: React.FC<HsSearchContainerProps> = props => {
  return <HsSearchComponent {...props} />;
};

const mapStateToProps = ({ productReducer }: AppState) => ({
  searchTerms: productReducer.searchTerms,
  searchResults: productReducer.searchResults,
  selected: productReducer.selected,
  error: productReducer.error,
  loading: productReducer.loading,
  notFound: productReducer.notFound
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ doChangeSearch, doSearchProducts, doSelectProduct, doDeselectProduct }, dispatch);

export type ReducerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(HsSearchContainer);
