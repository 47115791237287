import React, { FC } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import HsSearchResultsIndividual from '../../../components/HsSearch/HsSearchResultsIndividual';
import { doSelectProduct } from '../../../redux/modules/product/actions';
import { AppState } from '../../../redux/reducers';

export type ProductResultsProps = ProductResultsReducerProps & {
  hideActions?: boolean;
};

const ProductResults: FC<ProductResultsProps> = ({ selected, searchResults, doSelectProduct, hideActions }) => {
  // We are slicing the results to 150 to prevent hanging on the search function. If in the future hanging becomes and issue, or more results are needed, change the second number in the .slice() below
  const slicedSearchResults = searchResults.slice(0, 151);
  const selectedHSCodes = selected.map(({ HSCode }) => HSCode);

  return (
    <>
      {slicedSearchResults.map(({ HSCode, description }) => (
        <HsSearchResultsIndividual
          key={HSCode}
          HSCode={HSCode}
          description={description}
          selected={selectedHSCodes.includes(HSCode)}
          selectHsCode={(HSCode, description) => doSelectProduct({ HSCode, description })}
          hideActions={hideActions}
        />
      ))}
    </>
  );
};

const mapStateToProps = ({ productReducer }: AppState) => ({
  searchResults: productReducer.searchResults,
  selected: productReducer.selected
});

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({ doSelectProduct }, dispatch);

export type ProductResultsReducerProps = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>;

export default connect(mapStateToProps, mapDispatchToProps)(ProductResults);
